import request from '@/utils/request';

export default {
  data() {
    return {
      entertain: {
        title: '招待预约',
        url: '/canteenEntertainAuditFlowConfig/update',
        labelWidth: '300px',
        model: {
          node2Id: undefined,
          node2No: 2,
          node2: undefined,
          node3Id: undefined,
          node3No: 3,
          node3: undefined
        },
        rules: {},
        fields: [
          {
            label: '节点2：行政科审批人',
            prop: 'node2',
            type: 'select',
            placeholdder: '请选择行政科审批人',
            filterable: true,
            remote: true,
            options: [],
            remoteMethod: this.getNodeOptions(2, 0)
          },
          {
            label: '节点3：警保处审批人',
            prop: 'node3',
            type: 'select',
            placeholdder: '请选择警保处审批人',
            filterable: true,
            remote: true,
            options: [],
            remoteMethod: this.getNodeOptions(3, 1)
          }
        ]
      }
    };
  },
  methods: {
    getNodeOptions(type, index) {
      request
        .get('/canteenEntertainAuditFlowConfig/findByOptions', {
          params: { type }
        })
        .then(result => {
          return result.data.map(mapper);
        })
        .then(options => {
          this.entertain.fields[index].options = options;
        });

      function mapper(item) {
        return {
          label: item.name,
          value: item.id
        };
      }
    }
  }
};
