export default {
  data() {
    return {
      appointment: {
        title: '订餐预约',
        url: '/canteen/setting/saveSetting',
        labelWidth: '300px',
        model: { dayId: undefined, day: 0 },
        rules: {},
        fields: [
          {
            label: '提前几天开始预订',
            type: 'input-number',
            prop: 'day',
            min: 1,
            max: 7
          }
        ]
      }
    };
  }
};
