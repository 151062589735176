export default {
  data() {
    return {
      mealtime: {
        title: '开餐时间',
        url: '/canteen/mealTime/update',
        labelWidth: '300px',
        model: {
          id: undefined,
          breakfastStartTime: '',
          breakfastEndTime: '',
          lunchStartTime: '',
          lunchEndTime: '',
          dinnerStartTime: '',
          dinnerEndTime: ''
        },
        rules: {
          breakfastStartTime: {
            required: true,
            message: '早餐开始时间是必选的'
          },
          breakfastEndTime: { required: true, message: '早餐结束时间是必选的' },
          lunchStartTime: { required: true, message: '午餐开始时间是必选的' },
          lunchEndTime: { required: true, message: '午餐结束时间是必选的' },
          dinnerStartTime: { required: true, message: '晚餐开始时间是必选的' },
          dinnerEndTime: { required: true, message: '晚餐结束时间是必选的' }
        },
        fields: [
          {
            label: '早餐时间',
            prop1: 'breakfastStartTime',
            prop2: 'breakfastEndTime',
            startPlaceholder: '开始时间',
            endPlaceholder: '结束时间',
            type: 'timerange',
            valueFormat: 'HH:mm:ss',
            pickerOptions: { start: '07:00', end: '09:00', step: '00:10' }
          },
          {
            label: '午餐时间',
            prop1: 'lunchStartTime',
            prop2: 'lunchEndTime',
            startPlaceholder: '开始时间',
            endPlaceholder: '结束时间',
            type: 'timerange',
            valueFormat: 'HH:mm:ss',
            pickerOptions: { start: '11:00', end: '14:00', step: '00:10' }
          },
          {
            label: '晚餐时间',
            prop1: 'dinnerStartTime',
            prop2: 'dinnerEndTime',
            startPlaceholder: '开始时间',
            endPlaceholder: '结束时间',
            type: 'timerange',
            valueFormat: 'HH:mm:ss',
            pickerOptions: { start: '17:00', end: '20:00', step: '00:10' }
          }
        ]
      }
    };
  }
};
