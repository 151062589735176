<template>
  <app-page>
    <template #header>
      <div class="header">
        <el-radio-group
          class="group"
          fill="#E6A23C"
          :value="canteenType"
          @input="onCanteenChange"
        >
          <el-radio-button :disabled="canteenTypeSwitch === 2 && canteenTypeSwitch !== 999 " :label="1">一食堂</el-radio-button>
          <el-radio-button :disabled="canteenTypeSwitch === 1 && canteenTypeSwitch !== 999 " :label="2">二食堂</el-radio-button>
        </el-radio-group>
        <div class="title">参数配置<i class="el-icon-s-operation"></i></div>
      </div>
    </template>
    <template #main>
      <div class="options">
        <app-form-setting v-bind="mealtime" @blur="onSaveMealtime" />
        <app-form-setting
          v-bind="appointment"
          @blur="onSave('appointment', $event)"
        />
        <app-form-setting v-bind="temp" @blur="onSave('temp', $event)" />
        <app-form-setting v-bind="takeout" @blur="onSave('takeout', $event)" />
        <app-form-setting v-bind="entertain" @blur="onSaveNode" />
      </div>
    </template>
  </app-page>
</template>

<script>
import request from '@/utils/request';
import mixins from './mixins';

export default {
  name: 'Setting',
  mixins,
  data() {
    return {
      canteenType:Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
      canteenTypeSwitch:Number(sessionStorage.getItem('canteenType')),
    };
  },
  async created() {
    // 加载配置
    await this.getMealtime();
    await this.getSetting();
    await this.getAuditer();
  },
  methods: {
    // 食堂变更
    async onCanteenChange(e) {
      this.canteenType = e;
      await this.getMealtime();
      await this.getSetting();
      await this.getAuditer();
    },
    // 获得时间配置
    async getMealtime() {
      const { canteenType, mealtime, $message } = this;
      try {
        const result = await request.post('/canteen/mealTime', {
          canteenType
        });
        const [first] = result.data.records;
        mealtime.model = first;
      } catch (error) {
        $message.error('获得时间设置时出错');
      }
    },
    // 获得订餐、临时、外卖配置
    async getSetting() {
      const { canteenType, appointment, temp, takeout } = this;
      try {
        const result = await request.post('/canteen/setting/getSettingList', {
          canteenType
        });
        const [a, t1, t2, t3, w1, w2, w3, w4] = result.data.records;
        // 订餐
        appointment.model.dayId = a.id;
        appointment.model.day = a.nums;
        // 临时
        temp.model.breakfastId = t1.id;
        temp.model.breakfast = t1.nums;
        temp.model.lunchId = t2.id;
        temp.model.lunch = t2.nums;
        temp.model.dinnerId = t3.id;
        temp.model.dinner = t3.nums;
        // 外卖
        takeout.model.dayId = w2.id;
        takeout.model.day = w2.nums;
        takeout.model.numberId = w1.id;
        takeout.model.number = w1.nums;
        takeout.model.categoryId = w3.id;
        takeout.model.category = w3.nums;
        takeout.model.totalId = w4.id;
        takeout.model.total = w4.nums;
      } catch (error) {
        this.$message.error('获得设置列表时出错');
      }
    },
    // 获得审批人
    async getAuditer() {
      const { canteenType, entertain, $message } = this;
      try {
        const result = await request.get('/canteenEntertainAuditFlowConfig', {
          params: { canteenType }
        });
        const [first, second] = result.data.data;
        entertain.model.node2Id = first.id;
        entertain.model.node2 = first.userId;
        entertain.model.node3Id = second.id;
        entertain.model.node3 = second.userId;
      } catch (error) {
        $message.error('获得审批列表时出错');
      }
    },
    // 失去焦点时保存开餐时间
    async onSaveMealtime() {
      const { mealtime, $message } = this;
      await request.post(mealtime.url, mealtime.model);
      $message.success('已保存');
    },
    // 失去焦点时保存设置
    async onSave(key, field) {
      const appointment = this[key];
      const id = appointment.model[`${field.prop}Id`];
      const value = appointment.model[field.prop];
      const data = { id, nums: value };
      await request.post(appointment.url, data);
      this.$message.success('已保存');
    },
    // 失去焦点时保存审批人
    async onSaveNode(field) {
      const { entertain, $message } = this;
      const id = entertain.model[`${field.prop}Id`];
      const flowNo = entertain.model[`${field.prop}No`];
      const value = entertain.model[field.prop];
      const data = { id, flowNo, userId: value };
      await request.post(entertain.url, data);
      $message.success('已保存');
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  flex: 1 0;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #fff;

  .title {
    flex: 0 0 auto;
    font-size: 26px;
  }
}

.options {
  flex: 1 0;
  padding: 12px;
  background-color: #fff;
  overflow: auto;
}
</style>
