export default {
  data() {
    return {
      temp: {
        title: '临时预约',
        url: '/canteen/setting/saveSetting',
        labelWidth: '300px',
        model: {
          breakfastId: undefined,
          breakfast: 0,
          lunchId: undefined,
          lunch: 0,
          dinnerId: undefined,
          dinner: 0
        },
        rules: {},
        fields: [
          {
            label: '早餐限额',
            type: 'input-number',
            prop: 'breakfast'
          },
          {
            label: '午餐限额',
            type: 'input-number',
            prop: 'lunch'
          },
          {
            label: '晚餐限额',
            type: 'input-number',
            prop: 'dinner'
          }
        ]
      }
    };
  }
};
