export default {
  data() {
    return {
      takeout: {
        title: '外卖预约',
        url: '/canteen/setting/saveSetting',
        labelWidth: '300px',
        model: {
          dayId: undefined,
          day: 0,
          numberId: undefined,
          number: 0,
          categoryId: undefined,
          category: 0,
          totalId: undefined,
          total: 0
        },
        rules: {},
        fields: [
          {
            label: '提前几天开始预订',
            type: 'input-number',
            prop: 'day'
          },
          {
            label: '限额',
            type: 'input-number',
            prop: 'number'
          },
          {
            label: '预约的时候可以选多少种菜品',
            type: 'input-number',
            prop: 'category'
          },
          {
            label: '预约的时候所有菜品加起来的总数量不能超过',
            type: 'input-number',
            prop: 'total'
          }
        ]
      }
    };
  }
};
